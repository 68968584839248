
import {
	defineComponent,
	onMounted,
	watchEffect,
	ref,
	computed,
	onBeforeUnmount,
} from 'vue'
import { useStore, useRouter } from '@/utils/composables'
import { closeSwal, toast, Alert } from '@/dependencies/sweet-alert'
import { getV2Route } from '@/service/shared.http'

import Interfax from '@/components/client/interfax/interfax.vue'
import User from '@/components/client/users/user.vue'
import Cpt from '@/components/client/cpt/cpt.vue'
import Payer from '@/components/client/payer/payer.vue'
import Upload from '@/components/client/upload/cpt-pricing.vue'
import {
	IClientDetails,
	IClientTransaction,
} from '@server/api/models/fe/client'
import { IInboundFaxItem } from '@myndshft/types-mc-api'
import V2Cta from '@/components/shared/v2-cta.vue'
import { MyndshftPanel } from '@myndshft/panel'

export default defineComponent({
	props: {},
	name: 'client',
	components: {
		Interfax,
		User,
		Cpt,
		Payer,
		Upload,
		V2Cta,
		MyndshftPanel,
	},
	setup() {
		const store = useStore()
		const router = useRouter()

		const id = router.currentRoute.params.id || ''
		const state = ref(store.state.client)
		const isLoading = ref(true)
		const title = 'Add or update client configuration'
		const isSaving = ref(false)
		const isUploadingCSV = ref(false)
		const pageHeading = computed(() => {
			const client = state.value.client
			return `${client.name || ''} ${client.firstName || ''} ${
				client.lastName || ''
			}`.trim()
		})

		const setUploadingCSV = (isUploading: boolean) => {
			isUploadingCSV.value = isUploading
		}

		const updateConfig = async (
			config: IClientDetails,
			transactions: IClientTransaction[],
			inboundFax: IInboundFaxItem
		) => {
			closeSwal()

			const savingTimeout = setTimeout(() => {
				toast({
					type: Alert.INFO,
					title: 'Still attempting to save client. Please wait.',
				})
			}, 5000)
			try {
				await updateClient(config, transactions, inboundFax)
				await store.dispatch('client/getClient', state.value.client.id)
				toast({ type: Alert.SUCCESS, title: 'Successfully updated the client' })
			} catch (error) {
				toast({
					type: Alert.ERROR,
					title: 'Failed to update client',
					timer: 20000,
				})
			} finally {
				clearTimeout(savingTimeout)
				isSaving.value = false
			}
		}

		const getClient = () => {
			store
				.dispatch('client/getClient', id)
				.then(() => {
					isLoading.value = false
				})
				.catch(err => {
					// Error notification
					console.warn(err)
				})
		}

		const updateClient = async (
			clientConfig: IClientDetails,
			transactions: IClientTransaction[],
			inboundFax: IInboundFaxItem
		) => {
			isSaving.value = true
			const config = {
				client: {
					id: clientConfig.id,
					name: clientConfig.name,
					firstName: clientConfig.firstName,
					lastName: clientConfig.lastName,
					address: clientConfig.address,
					status: clientConfig.status,
					npi: clientConfig.npi,
					crmID: clientConfig.crmID,
					tenantId: clientConfig.tenantId,
					willAutoActivate: clientConfig.willAutoActivate,
					serviceTypeID: clientConfig.serviceTypeID,
					federalTaxId: clientConfig.federalTaxId,
					placeOfService: clientConfig.placeOfService,
				},
				transactions,
				inboundFax,
			}
			await store.dispatch('client/updateClient', {
				ID: config.client.id,
				config,
			})
			isSaving.value = false
		}

		watchEffect(() => {
			state.value = store.state.client
		})

		onMounted(() => {
			getClient()
		})

		onBeforeUnmount(() => {
			store.dispatch('client/reset')
		})

		return {
			state,
			isLoading,
			title,
			isSaving,
			id,
			pageHeading,
			isUploadingCSV,
			updateConfig,
			setUploadingCSV,
			getV2Route,
		}
	},
})
